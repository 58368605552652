import Vue from 'vue'
import Appsignal from '@appsignal/javascript'
import { errorHandler } from '@appsignal/vue'
import { plugin as pluginWindowEvents } from '@appsignal/plugin-window-events'
import { plugin as pluginPathDecorator } from '@appsignal/plugin-path-decorator'

const appsignal = new Appsignal({
  key: import.meta.env.VITE_APP_APPSIGNAL_FRONTEND_API_KEY,
  namespace: 'frontend',
})

appsignal.use(pluginWindowEvents({}))
appsignal.use(pluginPathDecorator({}))

Vue.config.errorHandler = errorHandler(appsignal, Vue)

export default appsignal
